export interface FormConfigurationLocalizedTextDefinition {
    content?: string;
    language?: string;
}

export const enum FormConfigurationOptionsDirection {
    Vertical = "vertical",
    Horizontal = "horizontal"
}

export interface FormConfigurationOptionDefinition {
    value: string;
    label: FormConfigurationLocalizedTextDefinition[];
    description?: FormConfigurationLocalizedTextDefinition[];
}

export const enum DataEntryUIControlType {
    Radio = "radiobutton",
    ImageRadio = "image-radio",
    Checkbox = "checkbox",
    Select = "dropdown",
    Autocomplete = "autocomplete",
    Textarea = "textarea",
    Text = "text",
    Date = "calendar",
    DateTime = "datetime",
    Integer = "integer",
    Float = "float",
    Dataset = "dataset",
    Paragraph = "paragraph",
    ExternalDatasetCount = "external-dataset-count",
    ExternalDataset = "external-dataset",
    CreateButton = "create-button"
}

export interface FormConfigurationAttribute {
    name: string;
    label?: string;
    inputControl?: DataEntryUIControlType;
    readonly?: boolean;
    include?: boolean;
    optionsDirection?: FormConfigurationOptionsDirection;
    help?: string;
    options?: FormConfigurationOptionDefinition[];
    defaultValue?: string | FormConfigurationAttributeInitializer; // Might actually be more types, but from data I have doesn't seem so
}

export const enum FormConfigurationExtensionType {
    Setter = "setter",
    Paragraph = "paragraph"
}

export interface FormConfigurationExtensionBase {
    name: string;
    type: FormConfigurationExtensionType;
    label?: string;
    help?: string;
    standalone?: boolean;
}

export interface FormConfigurationExtensionSetterOption {
    value: string;
    label: string;
}

export interface FormConfigurationExtensionSetter extends FormConfigurationExtensionBase {
    type: FormConfigurationExtensionType.Setter;
    attributesToSet: string[];
    options: FormConfigurationExtensionSetterOption[];
}

export interface FormConfigurationExtensionParagraph extends FormConfigurationExtensionBase {
    type: FormConfigurationExtensionType.Paragraph;
}

export type FormConfigurationExtension =
    | FormConfigurationExtensionSetter
    | FormConfigurationExtensionParagraph;

export enum FormConfigurationCreateFromExistingPickerType {
    LatestDate = "latestDate"
}

export interface FormConfigurationCreateFromExistingSettings {
    label?: string;
    excludeKeys?: string[];
    pickerType?: FormConfigurationCreateFromExistingPickerType;
}

export enum FormConfigurationSectionCategoryFieldType {
    Attribute = "attribute",
    Extension = "extension",
    Dataset = "dataset"
}

export interface FormConfigurationSectionCategoryField {
    type: FormConfigurationSectionCategoryFieldType;
    refName: string;
}

export interface FormConfigurationSectionCategory {
    name: string;
    label: string;
    fields: FormConfigurationSectionCategoryField[];
}

export interface FormConfigurationSection {
    name: string;
    label?: string;
    categories: FormConfigurationSectionCategory[];
}

export enum FormConfigurationAttributeInitializerType {
    Constant = "constant",
    DatasetVariable = "datasetVariable",
    CurrentDate = "currentDate",
    CurrentOrganization = "organizationId"
}

export interface FormConfigurationAttributeInitializer {
    value?: string;
    type: FormConfigurationAttributeInitializerType;
}

export interface FormConfigurationDatasetRelation {
    name: string;
    label: string;
    dataset: string;
    createFromExisting?: FormConfigurationCreateFromExistingSettings;
}

export interface FormConfigurationDataset {
    contractId: string;
    name: string;
    label?: string;
    keyedVariables: string[];
    initializationSection: FormConfigurationSection;
    extensions?: FormConfigurationExtension[];
    sections: FormConfigurationSection[];
    datasets?: FormConfigurationDatasetRelation[];
    attributes?: FormConfigurationAttribute[];
}

export interface FormConfiguration {
    author: string;
    businessId: string;
    datasets: FormConfigurationDataset[];
    contractId?: string;
    maintainer?: string;
    name: string;
    label?: string;
    uuid?: string;
    version?: string;
}

export type GetFormConfigurationResponseData = [FormConfiguration];
