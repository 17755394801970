import {
    DataEntryUIControlType,
    FormConfigurationAttribute,
    FormConfigurationAttributeInitializer,
    FormConfigurationAttributeInitializerType,
    FormConfigurationOptionsDirection,
    SubjectVariableData
} from "@fhir-api";
import { SubjectInstance } from "./subject-instance";
import {
    AttributeDataTypeEnum,
    isEnumScmAttribute,
    ScmAttachedExtension,
    ScmEntityAttribute,
    ValidationError
} from "@logex/expression-validator";
import { DatePipe } from "@angular/common";
import decodeHtmlEntities from "@app/util/html-util";

export interface IconResource {
    disabled?: boolean;
    src: string;
    tooltip: string;
}

export interface OptionImageDefinition {
    src: string;
    title?: string | null;
    titleColor?: string | null;
    dotColor: string | null;
    width: number | null;
    height: number | null;
}

export interface SubjectVariableOptionset {
    options: SubjectVariableOption[];
}

export interface SubjectVariableOption {
    value: string;
    label: string;
    description?: string;
    disabled: boolean;
    image?: OptionImageDefinition;
}

export const DEFAULT_BOOLEAN_OPTIONS: SubjectVariableOption[] = [
    {
        value: "true",
        label: "Ja",
        description: "Ja",
        disabled: false
    },
    {
        value: "false",
        label: "Nee",
        description: "Nee",
        disabled: false
    }
];

export class SubjectVariable {
    static create(
        scm: ScmEntityAttribute,
        parentSubject: SubjectInstance,
        data: SubjectVariableData,
        scmAttachedExtensions?: ScmAttachedExtension[],
        formConfiguration?: FormConfigurationAttribute
    ): SubjectVariable {
        return new SubjectVariable(
            scm,
            parentSubject,
            data,
            scmAttachedExtensions,
            formConfiguration
        );
    }

    get name(): string {
        return this._scm.name;
    }

    get label(): string {
        return this._formConfiguration?.label ?? this._scm.name;
    }

    get type(): AttributeDataTypeEnum {
        return this._scm.dataType;
    }

    get value(): string | undefined {
        return this._value;
    }

    set value(value: string | undefined) {
        this._value = value;
    }

    get defaultValue(): string | FormConfigurationAttributeInitializer | undefined {
        return this._formConfiguration?.defaultValue;
    }

    get included(): boolean {
        return this._formConfiguration?.include ?? true;
    }

    get visible(): boolean {
        return this._parentSubject.validations?.availability[this.name] ?? true;
    }

    get section(): string | undefined {
        return this._parentSubject.sections.find(section =>
            section.categories
                .flatMap(category => category.fields)
                .find(field => field.refName == this.name)
        )?.name;
    }

    get readonly(): boolean {
        return this._formConfiguration?.readonly ?? false;
    }

    get inputControl(): DataEntryUIControlType {
        return this._formConfiguration?.inputControl || this._inferInputControl(this._scm.dataType);
    }

    get controlVariant(): FormConfigurationOptionsDirection {
        return (
            this._formConfiguration?.optionsDirection ?? FormConfigurationOptionsDirection.Vertical
        );
    }

    get required(): boolean {
        return this._scm.required ?? false;
    }

    get length(): number {
        return (
            this._scm?.stringExtension?.maxLength ??
            this._scm?.rangeExtension?.maxValue?.toString()?.length ??
            20
        );
    }

    get format(): string | null {
        return null;
    }

    get help(): string {
        return this._formConfiguration?.help || "";
    }

    get resourceIcon(): IconResource | undefined {
        return undefined;
    }

    get hasOptions(): boolean {
        return this.optionset?.enumOptions !== undefined && this.optionset?.enumOptions?.length > 0;
    }

    get optionset(): ScmAttachedExtension | undefined {
        if (!isEnumScmAttribute(this._scm)) return undefined;
        const enumName = this._scm.referenceExtension?.enumName;
        return this._scmAttachedExtensions?.find(extension => extension.tableName === enumName);
    }

    get hiddenOptions(): string[] {
        const fieldsAvailability =
            this._parentSubject.validations?.fieldsOptionsAvailability[this.name];
        if (fieldsAvailability) {
            return Object.entries(fieldsAvailability)
                .filter(([key, value]) => value === false)
                .map(([key, value]) => key);
        }
        return [];
    }

    get valid(): boolean {
        return this.validations?.length > 0 ? false : true;
    }

    get validations(): ValidationError[] {
        return (
            this._parentSubject?.validations?.errors.filter(error =>
                error.attributes.includes(this.name)
            ) ?? []
        );
    }

    get isBarcodeScannerInput(): boolean {
        return false;
    }

    get parentSubject(): SubjectInstance {
        return this._parentSubject;
    }

    private _options: SubjectVariableOption[] = [];
    private _value: string | undefined;

    constructor(
        private _scm: ScmEntityAttribute,
        private _parentSubject: SubjectInstance,
        private _data: SubjectVariableData,
        private _scmAttachedExtensions?: ScmAttachedExtension[],
        private _formConfiguration?: FormConfigurationAttribute
    ) {
        const defaultValueDefinition = _formConfiguration?.defaultValue;
        let defaultValue;

        if (defaultValueDefinition) {
            if (defaultValueDefinition.hasOwnProperty("type")) {
                defaultValue = this._runInitializer(
                    defaultValueDefinition as FormConfigurationAttributeInitializer
                );
            } else {
                defaultValue = defaultValueDefinition as string;
            }
        }
        this._value = _data.value ?? defaultValue;
        this._setOptions();
    }

    getValueLabel(subjectValue?: string): string {
        if (this.hasOptions) {
            const option = this._options.find(o => o.value === subjectValue);
            return option?.label ?? "";
        }
        const dataset = this._parentSubject.parentDataset;
        return dataset.getFormattedValue(
            dataset.getVariableDefinition(this.name)!,
            subjectValue ?? ""
        );
    }

    getValidations(): ValidationError[] {
        return this.validations?.length ? this.validations : [];
    }

    getHiddenOptions(): Set<string> | null {
        if (this.hiddenOptions.length === 0) return null;

        return new Set(this.hiddenOptions);
    }

    getOptions(): SubjectVariableOption[] {
        const hidden = this.getHiddenOptions();
        if (hidden == null) return this._options;

        return this._options.filter(option => !hidden.has(option.value));
    }

    getOptionImage(subjectVariable?: SubjectVariable | null): OptionImageDefinition | null {
        if (!subjectVariable) return null;

        const options = this.getOptions();
        if (!options) return null;

        const option = options.find(o => o.value === subjectVariable.value);
        return option?.image ?? null;
    }

    private _setOptions(): void {
        if (isEnumScmAttribute(this._scm)) {
            this._options =
                this.optionset?.enumOptions?.map(option => {
                    const variableOption: SubjectVariableOption = {
                        value: option[0],
                        label: decodeHtmlEntities(option[1]) ?? "",
                        description: decodeHtmlEntities(option[1]) ?? "",
                        disabled: false // hardcoded to false, because the visibility is not yet implemented
                    };

                    return variableOption;
                }) || [];
        }
        if (this._options.length == 0 && this.type === AttributeDataTypeEnum.Boolean) {
            this._options = DEFAULT_BOOLEAN_OPTIONS;
        }
    }

    private _runInitializer(initializer: FormConfigurationAttributeInitializer): string {
        switch (initializer.type) {
            case FormConfigurationAttributeInitializerType.DatasetVariable:
                const variable = this._parentSubject
                    .getRootSubject()
                    .getVariable(initializer.value ?? "");
                return variable?.value ?? "";
            case FormConfigurationAttributeInitializerType.CurrentDate:
                const date = new Date();
                return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
            case FormConfigurationAttributeInitializerType.CurrentOrganization:
                return this._parentSubject.project.organizationId ?? "";
            case FormConfigurationAttributeInitializerType.Constant:
            default:
                return initializer.value ?? "";
        }
    }

    private _inferInputControl(dataType: AttributeDataTypeEnum): DataEntryUIControlType {
        switch (dataType) {
            case AttributeDataTypeEnum.Boolean:
                return DataEntryUIControlType.Radio;
            case AttributeDataTypeEnum.Date:
                return DataEntryUIControlType.Date;
            case AttributeDataTypeEnum.DateTime:
            case AttributeDataTypeEnum.Timestamp:
                return DataEntryUIControlType.DateTime;
            case AttributeDataTypeEnum.Enum:
                const optionsCount = this.optionset?.enumOptions?.length || 0;
                if (optionsCount <= 3) {
                    return DataEntryUIControlType.Radio;
                } else if (optionsCount < 6) {
                    return DataEntryUIControlType.Select;
                } else {
                    return DataEntryUIControlType.Autocomplete;
                }
            case AttributeDataTypeEnum.String:
                return DataEntryUIControlType.Text;
            default:
                return DataEntryUIControlType.Text;
        }
    }
}
