import {
    FormConfigurationDatasetRelation,
    FormConfigurationExtension,
    FormConfigurationSectionCategoryField
} from "./../../../../../../libs/fhir-api/src/lib/response/get-form-configuration-response-data";
import { SubjectInstance } from "./subject-instance";
import {
    DatasetData,
    FormConfigurationAttribute,
    FormConfigurationDataset,
    FormConfigurationSection,
    SubjectData
} from "@fhir-api";
import {
    AttributeDataTypeEnum,
    ScmEntityAttribute,
    ScmEntityList,
    SubjectDatasetEvaluation,
    SubjectEvaluation
} from "@logex/expression-validator";
import { Project } from "./project";
import { DatePipe } from "@angular/common";

export interface VariableDefinition
    extends Partial<FormConfigurationAttribute>,
        Partial<ScmEntityAttribute> {
    length: number;
}

export class SubjectDataset {
    static create(
        project: Project,
        datasetScm: ScmEntityList,
        parentSubject: SubjectInstance | null,
        data: DatasetData,
        datasetFormConfiguration?: FormConfigurationDataset
    ): SubjectDataset {
        return new SubjectDataset(
            project,
            datasetScm,
            parentSubject,
            data,
            datasetFormConfiguration
        );
    }

    static createEmpty(
        project: Project,
        datasetScm: ScmEntityList,
        parentSubject: SubjectInstance | null,
        datasetFormConfiguration?: FormConfigurationDataset
    ): SubjectDataset {
        const data: DatasetData = {
            name: datasetScm.businessAlias,
            subject: []
        };

        return SubjectDataset.create(
            project,
            datasetScm,
            parentSubject,
            data,
            datasetFormConfiguration
        );
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get name(): string {
        return this._scm.businessAlias;
    }

    get label(): string {
        return this._formConfiguration?.label ?? this._scm.businessAlias;
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        if (this.wordLabel.length === 0) {
            return this.wordLabel;
        }
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get subjects(): SubjectInstance[] {
        return this._subjects;
    }

    get preferredOrder(): number {
        return this._scm.preferredOrder;
    }

    get latestSubject(): SubjectInstance | null {
        return this._subjects[0] ?? null;
    }

    get parentDataset(): SubjectDataset | null {
        return this._parentSubject?.parentDataset ?? null;
    }

    get parentSubject(): SubjectInstance | null {
        return this._parentSubject;
    }

    get initializationSection(): FormConfigurationSection {
        return (
            this._formConfiguration?.initializationSection ?? {
                name: "init",
                categories: []
            }
        );
    }

    get sections(): FormConfigurationSection[] {
        return this.formConfiguration?.sections ?? [];
    }

    get extensions(): FormConfigurationExtension[] | undefined {
        return this.formConfiguration?.extensions;
    }

    get datasetRelations(): FormConfigurationDatasetRelation[] | undefined {
        return this.formConfiguration?.datasets;
    }

    get project(): Project {
        return this._project;
    }

    get scm(): ScmEntityList {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationDataset | undefined {
        return this._formConfiguration;
    }

    get primaryKey(): string[] {
        return this.scm.content.childContract.primaryKey;
    }

    get keyVariables(): string[] {
        return this.formConfiguration?.keyedVariables ?? this.primaryKey;
    }

    private _datePipe: DatePipe = new DatePipe("nl-NL");

    private _url = "";
    private _apiPath = "";
    private _subjects: SubjectInstance[] = [];
    private _subjectsLookup: Record<string, SubjectInstance> = {};
    private _validations: SubjectEvaluation[] = [];

    constructor(
        private _project: Project,
        private _scm: ScmEntityList,
        private _parentSubject: SubjectInstance | null,
        _data: DatasetData,
        private _formConfiguration?: FormConfigurationDataset
    ) {
        this._url = _data?.name;
        this._apiPath = _data?.name;
        if (this._parentSubject) {
            this._url = `${this._parentSubject.url}/${this._url}`;
            this._apiPath = `${this._parentSubject?.parentDataset.apiPath}.${this._apiPath}`;
        }

        this._processSubjects(_data?.subject ?? []);
    }

    createNewEmptySubject(): SubjectInstance {
        const subject = SubjectInstance.create(this, this._parentSubject, {
            name: "initializationSubject"
        });
        this._subjects.push(subject);
        this._subjectsLookup[subject.name] = subject;
        return subject;
    }

    createNewSubject(data: SubjectData): SubjectInstance {
        const subject = SubjectInstance.create(this, this._parentSubject, data);
        this._subjects.push(subject);
        this._subjectsLookup[subject.name] = subject;
        return subject;
    }

    getSubject(name: string): SubjectInstance | null {
        return this._subjectsLookup[name] ?? null;
    }

    getVariableDefinition(name: string): VariableDefinition | undefined {
        const formConfigurationAttribute = this._formConfiguration?.attributes?.find(
            attribute => attribute.name === name
        );
        const scmAttribute = this._scm.content.childContract.content.attributes.find(
            attribute => attribute.name === name
        );
        const length =
            scmAttribute?.stringExtension?.maxLength ??
            scmAttribute?.rangeExtension?.maxValue?.toString()?.length ??
            20;
        return {
            ...(formConfigurationAttribute ?? {}),
            ...(scmAttribute ?? {}),
            length
        };
    }

    getFormattedValue(variableDefinition: VariableDefinition, value: string): string {
        if (variableDefinition.dataType === AttributeDataTypeEnum.Date) {
            return value ? (this._datePipe.transform(new Date(value), "dd-MM-yyyy") ?? "") : "";
        }
        if (variableDefinition.dataType === AttributeDataTypeEnum.DateTime) {
            return value
                ? (this._datePipe.transform(new Date(value), "dd-MM-yyyy HH:mm") ?? "")
                : "";
        }
        if (variableDefinition.dataType === AttributeDataTypeEnum.Timestamp) {
            const date_value = isNaN(parseInt(value)) ? parseInt(value) : value;
            return date_value
                ? (this._datePipe.transform(new Date(date_value), "dd-MM-yyyy HH:mm") ?? "")
                : "";
        }
        if (variableDefinition.dataType === AttributeDataTypeEnum.Time) {
            return value ? (this._datePipe.transform(new Date(value), "HH:mm") ?? "") : "";
        }
        return value;
    }

    updateDatasetValidation(evaluation: SubjectDatasetEvaluation): void {
        for (const subjectEvaluation of evaluation.subjects) {
            this._subjectsLookup[subjectEvaluation.name].updateSubjectValidation(subjectEvaluation);
            for (const childDataset of this._subjectsLookup[subjectEvaluation.name].datasets) {
                const sectionEvaluation = subjectEvaluation.datasets.find(
                    dataset => dataset.name === childDataset.name
                ) || {
                    name: childDataset.name,
                    subjects: [] // TODO maybe also prefill or solve the empty subjects array case when subjects are not empty
                };
                childDataset.updateDatasetValidation(sectionEvaluation);
            }
        }
    }

    private _processSubjects(subjectsData: SubjectData[]): void {
        for (const subjectData of subjectsData) {
            const subject = new SubjectInstance(this, this._parentSubject, subjectData);
            this._subjects.push(subject);
            this._subjectsLookup[subjectData.name] = subject;
        }
        this._subjects.sort((a, b) => {
            let firstValue = a.getVariable(a.keyVariables[0].name)?.value ?? "";
            let secondValue = b.getVariable(b.keyVariables[0].name)?.value ?? "";
            return -firstValue.localeCompare(secondValue);
        });
    }
}
