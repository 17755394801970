import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { SubjectInstance } from "@app/model";

import {
    Auth0AuthorizationService,
    AuthorizationOrganization,
    MrdmAuthService
} from "@authorization";

import { INavNode } from "@logex/framework/lg-application";
import { LgTranslateService } from "@logex/framework/lg-localization";

import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    filter,
    first,
    map,
    Observable,
    OperatorFunction,
    throwError
} from "rxjs";
import { AppDialogService } from "../app-dialog.service";
import { AppStorageService } from "../app-storage.service";

export interface RequestErrorActionStructure {
    [key: number]: {
        lcKey?: string;
        ignore?: boolean;
        action?: () => any;
    };

    default?: {
        lcKey?: string;
        action?: () => any;
    };
}

@Injectable({
    providedIn: "root"
})
export class AppStateService {
    private _appStorage = inject(AppStorageService);
    private _auth0Service = inject(Auth0AuthorizationService);
    private _authService = inject(MrdmAuthService);
    private _router = inject(Router);
    private _translateService = inject(LgTranslateService);
    private _dialogService = inject(AppDialogService);

    private _selectedRegistryName = new BehaviorSubject<string | null | undefined>(undefined);

    private _selectedOrganization = new BehaviorSubject<
        AuthorizationOrganization | null | undefined
    >(undefined);

    private _navigation = new BehaviorSubject<INavNode[]>([]);

    private _backUrl = "";

    private readonly _sessionKeys = {
        organizationAgreed: "statementAgreedOrganizationUri",
        projectAgreed: "statementAgreedProjectUri"
    };

    public readonly selectedRegistryName$ = this._selectedRegistryName
        .asObservable()
        .pipe(filter((project): project is string => project != null));

    public readonly selectedOrganization$ = this._selectedOrganization.asObservable().pipe(
        distinctUntilChanged(),
        filter((organization): organization is AuthorizationOrganization => organization != null)
    );

    public readonly selectedOrganizationId$ = this.selectedOrganization$.pipe(
        map(organization => organization.cicCode),
        filter((organizationId): organizationId is number => organizationId !== null)
    );

    public readonly selectedOrganizationUri$ = this.selectedOrganization$.pipe(
        map(organization => organization.mrdmUri),
        filter((organizationUri): organizationUri is string => organizationUri !== null)
    );

    public readonly navigation$ = this._navigation.asObservable();

    public readonly organizations$ = this._getOrganizations$();

    reload(): void {
        window.location.reload();
    }

    selectOrganizationAndRegistry(
        organization: AuthorizationOrganization | number | string | null,
        registryName: string | null
    ): void {
        this._selectedRegistryName.next(registryName);

        this.organizations$.pipe(first()).subscribe(organizations => {
            const organizationObject = organizations.find(org => {
                if (typeof organization === "number") {
                    return org.cicCode === organization;
                } else if (typeof organization === "string") {
                    return org.mrdmUri === organization;
                } else {
                    return org.cicCode === organization?.cicCode;
                }
            });

            if (organizationObject) {
                this._selectedOrganization.next(organizationObject);
            } else {
                this.setErrorLc(
                    "Organization_not_found",
                    {
                        organization:
                            typeof organization === "object"
                                ? (organization?.mrdmUri ?? "")
                                : organization
                    },
                    () => {
                        this._router.navigateByUrl("/select");
                    }
                );
            }
        });
    }

    setErrorLc(
        lcKey: string,
        interpolateParams: Record<string, string | number> | (() => any),
        action?: () => any
    ): void {
        if (typeof interpolateParams === "function" && !action) {
            action = interpolateParams;
            interpolateParams = {};
        }

        this._dialogService.displayErrorMessage(
            this._translateService.translate("APP._Error." + lcKey, interpolateParams),
            action
        );
    }

    setNavigation(navigation: INavNode[]): void {
        this._navigation.next(navigation);
    }

    setBackUrl(url: string): void {
        this._backUrl = url;
    }

    navigateBack({ fallbackUrl }: { fallbackUrl: string }): void {
        const backUrl = this._backUrl;
        this._backUrl = "";

        this._router.navigateByUrl(backUrl || fallbackUrl);
    }

    catchRequestError$<T>(
        requestErrorAction?: RequestErrorActionStructure
    ): OperatorFunction<T, T> {
        return catchError((httpErrorResponse: HttpErrorResponse) => {
            const status = httpErrorResponse.status;

            // forced behaviour if 401
            if (httpErrorResponse.status === 401) {
                this.setErrorLc("Unauthorized", () => this._auth0Service.logout());
            } else if (!requestErrorAction?.[status]?.ignore) {
                const action =
                    requestErrorAction?.[httpErrorResponse.status]?.action ??
                    requestErrorAction?.default?.action;
                const lcKey =
                    requestErrorAction?.[httpErrorResponse.status]?.lcKey ??
                    requestErrorAction?.default?.lcKey ??
                    "Request_error";
                const message =
                    httpErrorResponse.error?.errors?.subjectPath?.[0] ??
                    httpErrorResponse.error?.message ??
                    httpErrorResponse.message;

                if (httpErrorResponse.status === 500) {
                    const footer = this._translateService.translate(
                        "APP._Error.Request_error_footer"
                    );
                    this.setErrorLc(lcKey, { message, footer }, action);
                } else {
                    this.setErrorLc(lcKey, { message, footer: "" }, action);
                }
            }

            return throwError(() => httpErrorResponse);
        });
    }

    setAgreedStatementInfo(organizationUri: string, registryName: string): void {
        this._appStorage.setToSessionStorage(this._sessionKeys.organizationAgreed, organizationUri);
        this._appStorage.setToSessionStorage(this._sessionKeys.projectAgreed, registryName);
    }

    getAgreedStatementInfo(): { organizationUri: string | null; registryName: string | null } {
        return {
            organizationUri: this._appStorage.getFromSessionStorage(
                this._sessionKeys.organizationAgreed
            ),
            registryName: this._appStorage.getFromSessionStorage(this._sessionKeys.projectAgreed)
        };
    }

    clearAgreedStatementInfo(): void {
        this._appStorage.removeFromSessionStorage(this._sessionKeys.organizationAgreed);
        this._appStorage.removeFromSessionStorage(this._sessionKeys.projectAgreed);
    }

    private _getOrganizations$(): Observable<AuthorizationOrganization[]> {
        return this._authService.userOrganizations$.pipe(
            map(organizations =>
                organizations?.filter((org: AuthorizationOrganization) => org.cicCode !== null)
            )
        );
    }
}
