import {
    DataEntryUIControlType,
    FormConfiguration,
    FormConfigurationCreateFromExistingPickerType,
    FormConfigurationExtensionType,
    FormConfigurationOptionsDirection,
    FormConfigurationSectionCategoryFieldType,
    GetFormConfigurationResponseData
} from "./get-form-configuration-response-data";

export const DCRA_2024_FORM_CONFIGURATION_TESTDATA: FormConfiguration = {
    uuid: "generated UUID",
    businessId: "DHBA_test_patient_form",
    name: "dcra-2024",
    author: "Victoria Gardukevich",
    maintainer: "Victoria Gardukevich",
    version: "1.0.0",
    contractId: "strong contract UUID",
    datasets: [
        {
            name: "patient",
            contractId: "list strong contract UUID",
            label: "Patient",
            keyedVariables: ["land", "idcode"],
            attributes: [
                {
                    name: "id",
                    label: "Zorginstelling"
                },
                {
                    name: "upn",
                    label: "Uniek Patiëntnummer",
                    help: "Het zieknhuis patiëntnummer (UPN) zoals deze in het elektronisch patiënten dossier (EPD) van het desbetreffende ziekenhuis staat geregistreerd."
                },
                {
                    name: "land",
                    label: "Land Burger Service Nummer",
                    inputControl: DataEntryUIControlType.Radio
                },
                {
                    name: "idcode",
                    label: "Burger Service Nummer"
                },
                {
                    name: "tussen",
                    label: "Voorvoegsels"
                },
                {
                    name: "naam",
                    label: "Geslachtsnaam"
                },
                {
                    name: "gebdat",
                    label: "Geboortedatum"
                },
                {
                    name: "geslacht",
                    label: "Geslacht",
                    inputControl: DataEntryUIControlType.Radio
                },
                {
                    name: "land-woon",
                    label: "Land (Woonadres)",
                    defaultValue: "nl"
                },
                {
                    name: "pcode",
                    label: "Postcode"
                },
                {
                    name: "datovl",
                    label: "Datum overlijden (indien van toepassing)"
                }
            ],
            datasets: [
                {
                    dataset: "comorbiditeiten",
                    name: "comorbiditeiten",
                    label: "Commorbiditeiten",
                    createFromExisting: {
                        pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                    }
                },
                {
                    dataset: "presentatie",
                    name: "presentatie",
                    label: "Presentatie",
                    createFromExisting: {
                        pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                    }
                }
            ],
            initializationSection: {
                name: "init",
                categories: [
                    {
                        name: "init-1",
                        label: "",
                        fields: [
                            {
                                refName: "land",
                                type: FormConfigurationSectionCategoryFieldType.Attribute
                            },
                            {
                                refName: "idcode",
                                type: FormConfigurationSectionCategoryFieldType.Attribute
                            }
                        ]
                    }
                ]
            },
            sections: [
                {
                    name: "patient-1",
                    label: "Patient details",
                    categories: [
                        {
                            name: "patient-1-1",
                            label: "",
                            fields: [
                                {
                                    refName: "id",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "upn",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "land",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "idcode",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "tussen",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "naam",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "gebdat",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "geslacht",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "land-woon",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "pcode",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "datovl",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "patient-2",
                    label: "Commorbidities",
                    categories: [
                        {
                            name: "patient-2-1",
                            label: "",
                            fields: [
                                {
                                    type: FormConfigurationSectionCategoryFieldType.Dataset,
                                    refName: "comorbiditeiten"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "patient-3",
                    label: "Presentations",
                    categories: [
                        {
                            name: "patient-3-1",
                            label: "",
                            fields: [
                                {
                                    type: FormConfigurationSectionCategoryFieldType.Dataset,
                                    refName: "presentatie"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            name: "comorbiditeiten",
            label: "Commorbiditeiten",
            keyedVariables: ["datcom", "patient_upn"],
            extensions: [
                {
                    name: "test-setter",
                    type: FormConfigurationExtensionType.Setter,
                    label: ".SetterLabel",
                    attributesToSet: ["commyo", "comhartfaal"],
                    options: [
                        {
                            value: "true",
                            label: "Yes to all"
                        },
                        {
                            value: "false",
                            label: "No to all"
                        }
                    ]
                },
                {
                    name: "test-paragraph",
                    type: FormConfigurationExtensionType.Paragraph,
                    label: ".ParagraphLabel"
                }
            ],
            attributes: [
                {
                    name: "datcom",
                    label: "Datum comorbiditeiten"
                },
                {
                    name: "commyo",
                    label: "Myocardinfarct",
                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                },
                {
                    name: "comhartfaal",
                    label: "Congestief hartfalen",
                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                }
            ],
            initializationSection: {
                name: "init",
                categories: [
                    {
                        name: "init-1",
                        label: "",
                        fields: [
                            {
                                refName: "datcom",
                                type: FormConfigurationSectionCategoryFieldType.Attribute
                            }
                        ]
                    }
                ]
            },
            sections: [
                {
                    name: "comorbiditeiten-1",
                    label: "Commorbiditeiten",
                    categories: [
                        {
                            name: "comorbiditeiten-1-1",
                            label: "",
                            fields: [
                                {
                                    refName: "test-paragraph",
                                    type: FormConfigurationSectionCategoryFieldType.Extension
                                },
                                {
                                    refName: "datcom",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "test-setter",
                                    type: FormConfigurationSectionCategoryFieldType.Extension
                                },
                                {
                                    refName: "commyo",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "comhartfaal",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            name: "presentatie",
            label: "Presentatie",
            keyedVariables: ["datpa1"],
            datasets: [
                {
                    dataset: "diagnostiek",
                    name: "diagnostiek",
                    label: "Diagnostiek",
                    createFromExisting: {
                        pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                    }
                }
            ],
            attributes: [
                {
                    name: "datpa1",
                    label: "Datum presentatie"
                },
                {
                    name: "lengte",
                    label: "Lengte"
                },
                {
                    name: "gewicht",
                    label: "Gewicht"
                },
                {
                    name: "tumor_upn",
                    include: false
                }
            ],
            initializationSection: {
                name: "init",
                categories: [
                    {
                        name: "init-1",
                        label: "",
                        fields: [
                            {
                                refName: "datapa1",
                                type: FormConfigurationSectionCategoryFieldType.Attribute
                            }
                        ]
                    }
                ]
            },
            sections: [
                {
                    name: "presentatie-1",
                    label: "Presentatie",
                    categories: [
                        {
                            name: "presentatie-1-1",
                            label: "",
                            fields: [
                                {
                                    refName: "datpa1",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "lengte",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "gewicht",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                },
                                {
                                    refName: "tumor_upn",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "presentatie-2",
                    label: "Diagnostiek",
                    categories: [
                        {
                            name: "presentatie-2-1",
                            label: "",
                            fields: [
                                {
                                    refName: "diagnostiek",
                                    type: FormConfigurationSectionCategoryFieldType.Dataset
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            contractId: "list strong contract UUID",
            name: "diagnostiek",
            keyedVariables: ["primcrc"],
            label: "Diagnostiek",
            initializationSection: {
                name: "init",
                categories: [
                    {
                        name: "init-1",
                        label: "",
                        fields: [
                            {
                                refName: "primcrc",
                                type: FormConfigurationSectionCategoryFieldType.Attribute
                            }
                        ]
                    }
                ]
            },
            sections: [
                {
                    name: "diagnostiek-1",
                    label: "Diagnostiek",
                    categories: [
                        {
                            name: "diagnostiek-1-1",
                            label: "",
                            fields: [
                                {
                                    refName: "presentatie_datpa1",
                                    type: FormConfigurationSectionCategoryFieldType.Attribute
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

import * as jsonData from "./form-configuration-enriched.json";

export const DCRA_2024_FULL_FC: FormConfiguration = jsonData as FormConfiguration;
export const GET_FORM_CONFIGURATION_RESPONSE_TESTDATA: GetFormConfigurationResponseData = [
    DCRA_2024_FULL_FC
];
