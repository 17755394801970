import {
    DatasetData,
    FormConfigurationDataset,
    FormConfigurationDatasetRelation,
    FormConfigurationExtension,
    FormConfigurationSection,
    SubjectData,
    SubjectVariableData
} from "@fhir-api";

import { SubjectDataset } from "./subject-dataset";
import { SubjectVariable } from "./subject-variable";

import {
    evaluateRootDataset,
    RootDatasetData,
    ScmEntityList,
    ScmEvaluation,
    SubjectEvaluation,
    ValidatorDatasetData,
    ValidatorSubjectData
} from "@logex/expression-validator";
import { Project } from "./project";

export interface TranslationMessage {
    message: string;
    messageVariables?: Record<string, string | number>;
}

export class SubjectInstance {
    static create(
        parentDataset: SubjectDataset,
        parentSubject: SubjectInstance | null,
        data: SubjectData
    ): SubjectInstance {
        return new SubjectInstance(parentDataset, parentSubject, data);
    }

    get name(): string {
        return this._name;
    }

    get label(): string {
        const label = this.keyVariables
            .map(variable => {
                const subjectVariable = this.getVariable(variable.name);
                return variable.getValueLabel(subjectVariable?.value);
            })
            .join("/");
        return label.length > 0 ? label : "-";
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        if (this.wordLabel.length === 0) {
            return this.wordLabel;
        }
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get attributes(): SubjectVariable[] {
        return this._subjectVariables;
    }

    get sections(): FormConfigurationSection[] {
        return this.formConfiguration?.sections ?? [];
    }

    get extensions(): FormConfigurationExtension[] | undefined {
        return this.formConfiguration?.extensions;
    }

    get datasetRelations(): FormConfigurationDatasetRelation[] | undefined {
        return this.formConfiguration?.datasets;
    }

    get validations(): SubjectEvaluation | null {
        return this._validations ?? null;
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get urlSegments(): string[] {
        return [this._parentDataset.name, this.name];
    }

    get canDelete(): boolean {
        return true;
    }

    get parentDataset(): SubjectDataset {
        return this._parentDataset;
    }

    get datasets(): SubjectDataset[] {
        return this._subjectDatasets;
    }

    get parentSubject(): SubjectInstance | null {
        return this._parentSubject;
    }

    get variables(): SubjectVariable[] {
        return this._subjectVariables;
    }

    get visible(): boolean {
        for (const variable of this._subjectVariables) {
            if (variable.visible) {
                return true;
            }
        }
        return false;
    }

    get valid(): boolean {
        return this.getValidationMessages(true).length === 0;
    }

    get scm(): ScmEntityList {
        return this._parentDataset.scm;
    }

    get formConfiguration(): FormConfigurationDataset | undefined {
        return this._parentDataset.formConfiguration;
    }

    get project(): Project {
        return this._parentDataset.project;
    }

    /**
     * Returns the key variables for this dataset.
     * These variables are used when creating a new subject.
     */
    get keyVariables(): SubjectVariable[] {
        const keyVariableNames =
            this.formConfiguration?.keyedVariables ?? this.scm.content.childContract.primaryKey;
        return this._subjectVariables.filter(
            attribute => keyVariableNames.includes(attribute.name) && attribute.included
        );
    }

    private _url = "";
    private _name!: string;
    private _apiPath = "";
    private _subjectDatasets: SubjectDataset[] = [];
    private _subjectDatasetsLookup: Record<string, SubjectDataset> = {};
    private _subjectDatasetsNameLookup: Record<string, SubjectDataset> = {};
    private _subjectVariables: SubjectVariable[] = [];
    private _subjectVariablesLookup: Record<string, SubjectVariable> = {};
    private _validations: SubjectEvaluation | null = null;

    constructor(
        private _parentDataset: SubjectDataset,
        private _parentSubject: SubjectInstance | null,
        _data: SubjectData
    ) {
        this._name = _data.name;
        if (!this.parentSubject) {
            // root subject
            this._url = `subject/${this.name}`;
        } else {
            this._url += `${_parentDataset.url}/${this.name}`;
        }

        if (this._parentSubject) {
            this._apiPath = `${this._parentSubject.apiPath}.${this.name}`;
        } else {
            this._apiPath = this.name;
        }
        const datasetDataLookup: Record<string, DatasetData> = (_data?.dataset ?? []).reduce(
            (acc, dataset) => {
                acc[dataset.name] = dataset;
                return acc;
            },
            {} as Record<string, DatasetData>
        );
        const variableDataLookup: Record<string, SubjectVariableData> = (
            _data?.variable ?? []
        ).reduce(
            (acc, dataset) => {
                acc[dataset.name] = dataset;
                return acc;
            },
            {} as Record<string, SubjectVariableData>
        );
        this._processDatasets(datasetDataLookup);
        this._processVariables(variableDataLookup);
    }

    getSection(name: string): FormConfigurationSection | undefined {
        return this.sections.find(section => section.name === name);
    }

    getDefaultSection(): FormConfigurationSection | undefined {
        return this.sections.at(0);
    }

    getDataset(name: string): SubjectDataset | null {
        return this._subjectDatasetsLookup[name] ?? this._subjectDatasetsNameLookup[name] ?? null;
    }

    getRootDataset(): SubjectDataset {
        return this.project.rootDataset;
    }

    getRootSubject(): SubjectInstance {
        let root = this._parentSubject;
        while (root?.parentSubject) {
            root = root.parentSubject;
        }
        return root ?? this;
    }

    getVariable(variableName: string): SubjectVariable | undefined {
        return this._subjectVariablesLookup[variableName];
    }

    getExtension(extensionName: string): FormConfigurationExtension | undefined {
        return this.extensions?.find(extension => extension.name === extensionName);
    }

    getDatasetRelation(relationName: string): FormConfigurationDatasetRelation | undefined {
        return this.datasetRelations?.find(relation => relation.name === relationName);
    }

    getValidatorData(creation: boolean = false): ValidatorDatasetData {
        return {
            name: this.scm.content.name,
            subject: [
                {
                    name: this.name,
                    variable: this.variables.reduce(
                        (acc, variable) => {
                            if (variable.value !== undefined || !creation) {
                                acc[variable.name] = variable.value;
                            }
                            return acc;
                        },
                        {} as Record<string, string | undefined>
                    ),
                    dataset: this.datasets.map(dataset => {
                        return {
                            name: dataset.scm.content.name,
                            subject: dataset.subjects
                                .map(subject => subject.getValidatorData(creation)?.subject?.[0])
                                .filter(subject => !!subject)
                        };
                    })
                }
            ]
        };
    }

    public validate(creation: boolean = false): ScmEvaluation | null {
        const values: RootDatasetData = {
            data: this.getValidatorData(creation)
        };
        let validatedData: ScmEvaluation;
        try {
            validatedData = evaluateRootDataset(this.project.scm, values, creation);
        } catch (error) {
            throw new Error("Failed to validate data: " + error);
        }
        if (!validatedData.rootSubjectDataset.subjects.length) {
            throw new Error("No subjects found in validated data");
        }
        this.updateSubjectValidation(validatedData.rootSubjectDataset.subjects[0]);
        return validatedData;
    }

    getValidationMessages(
        includeChildren = false,
        isInitialization = false,
        forSection?: string
    ): TranslationMessage[] {
        const subjectMessages = this._subjectVariables
            .filter(
                subjectVariable =>
                    !subjectVariable.valid &&
                    (!forSection || this._fieldBelongsToSection(subjectVariable, forSection)) &&
                    (!isInitialization || this._checkedOnInitialization(subjectVariable))
            )
            .flatMap(subjectVariable =>
                subjectVariable
                    .getValidations()
                    .map<TranslationMessage>(validationData => ({
                        message: validationData.message,
                        messageVariables: validationData.messageVariables
                    }))
                    .filter(
                        translationMessage => (translationMessage.message?.trim().length ?? 0) > 0
                    )
            );

        const childrenMessages = includeChildren
            ? this._subjectDatasets
                  .flatMap(dataset => dataset.subjects)
                  .flatMap(subject =>
                      subject.getValidationMessages(true, isInitialization, forSection)
                  )
            : [];
        return [...subjectMessages, ...childrenMessages];
    }

    setVariableValue(variableName: string, value: string): void {
        const variable = this.getVariable(variableName);
        if (variable) {
            const variableIndex = this._subjectVariables.findIndex(
                value => value.name === variableName
            );
            const changedVariable = Object.create(variable);
            changedVariable.value = value;
            this._subjectVariables[variableIndex] = changedVariable;
            this._subjectVariablesLookup[variableName] = changedVariable;
        }
    }

    updateSubjectValidation(evaluation: SubjectEvaluation): void {
        this._validations = evaluation;
        for (const childDataset of evaluation.datasets) {
            this._subjectDatasetsNameLookup[childDataset.name].updateDatasetValidation(
                childDataset
            );
        }
    }

    private _checkedOnInitialization(subjectVariable: SubjectVariable): boolean {
        return this.keyVariables
            .map(keyVariable => keyVariable.name)
            .includes(subjectVariable.name);
    }

    private _fieldBelongsToSection(subjectVariable: SubjectVariable, sectionName: string): boolean {
        return subjectVariable.section === sectionName;
    }

    private _processDatasets(datasetData: Record<string, DatasetData>): void {
        for (const datasetRelation of this.formConfiguration?.datasets ?? []) {
            const datasetScm = this.project.getDatasetScm(datasetRelation.dataset);
            if (!datasetScm) {
                console.error("FAILED TO FIND DATASET SCM", datasetRelation.dataset);
                continue;
            }
            const datasetFormConfiguration = this.project.getDatasetFormConfiguration(
                datasetRelation.dataset
            );
            const subjectDataset = new SubjectDataset(
                this.project,
                datasetScm,
                this,
                datasetData[datasetRelation.dataset] ?? {
                    name: datasetRelation.dataset,
                    subject: []
                },
                datasetFormConfiguration
            );
            this._subjectDatasets.push(subjectDataset);
            this._subjectDatasetsLookup[datasetRelation.dataset] = subjectDataset;
            this._subjectDatasetsNameLookup[subjectDataset.scm.content.name] = subjectDataset;
        }
    }

    private _processVariables(variablesData: Record<string, SubjectVariableData>): void {
        for (const attributeScm of this.scm.content.childContract.content.attributes ?? []) {
            const attributeFormConfiguration = this.formConfiguration?.attributes?.find(
                attribute => {
                    return attribute.name === attributeScm.name;
                }
            );
            const variable = SubjectVariable.create(
                attributeScm,
                this,
                variablesData[attributeScm.name] ?? {
                    name: attributeScm.name
                },
                this.scm.content.childContract.content.attachedExtension || [],
                attributeFormConfiguration
            );
            this._subjectVariables.push(variable);
            this._subjectVariablesLookup[attributeScm.name] = variable;
        }
    }
}
